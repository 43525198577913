<template>
  <div class="works">
    <LeftMenu current="songManage"></LeftMenu>
    <div class="main" :class="{songManage:$route.meta.active}">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
import LeftMenu from '../../components/LeftMenu'

export default {
  name: "index",
  components: { LeftMenu},
}
</script>
<!--样式统一放三级页面-->
<style lang="scss" scoped>
@import '../../styles/works';
.songManage{
  padding: 0;
  background-color: #f7f7f7;
}
</style>
